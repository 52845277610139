// App.js
// import React, { useEffect, useState } from 'react';
// import ProductWall from './components/ProductWall';
// import Footer from './components/Footer';
// import Nav from './components/Nav';
// import SamplePacks from './components/SamplePacks';
// import DrumKits from './components/DrumKits';
// import Presets from './components/Presets';
// import About from './components/About';
// import Email from './components/Email';
// import Subscriber from './components/Subscriber';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Add inline styles for fade-in effect
// const styles = {
//   fadeIn: {
//     opacity: 0,
//     transition: 'opacity 1.5s ease-in-out',
//   },
//   fadeInShow: {
//     opacity: 1,
//   },
// };

function App() {
//   const [jsonData, setJsonData] = useState(null);
//   const [showContent, setShowContent] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch('.netlify/functions/api');
//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         const data = await response.json(); // Parse JSON
//         setJsonData(data);
//         setLoading(false);
//         setShowContent(true); // Set showContent to true when data is fetched
//         console.log(data);
//       } catch (error) {
//         setError(error.message || 'An error occurred');
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Sorry, the website is currently down</h1>
      <p>We apologize for the inconvenience, but the client has not paid for the hosting services.</p>
      <p>Please contact the website owner to resolve this issue.</p>
    </div>
    // <div style={{ ...styles.fadeIn, ...(showContent && styles.fadeInShow), width: '100%' }}>
    // <p>Hello</p>
    // <Router>
    //     <Email/>
    //     <Nav />
    //     <Routes>
    //       <Route
    //         path="/"
    //         element={<ProductWall products={jsonData?.products || []} />}
    //       />
    //       <Route
    //         path="/samplePacks"
    //         element={<SamplePacks products={jsonData?.products || []} />}
    //       />
    //       <Route
    //         path="/drumKits"
    //         element={<DrumKits products={jsonData?.products || []} />}
    //       />
    //       <Route
    //         path="/presets"
    //         element={<Presets products={jsonData?.products || []} />}
    //       />
    //       <Route
    //         path="/about"
    //         element={<About products={jsonData?.products || []} />}
    //       />
    //       <Route
    //         path="/newsletter"
    //         element={<Subscriber/>}
    //       />
    //       {/* Add other routes for different pages */}
    //     </Routes>
    //   </Router>
    //   {loading && <p>Loading...</p>}
    //   {error && <p>Error: {error}</p>}
    //   {/* {jsonData?.success && <ProductWall products={jsonData.products} />} */}
    //   <Footer />
    // </div>
  );
}

export default App;
